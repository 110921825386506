import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialLinks from "../components/SocialLinks";
import Img from "gatsby-image";

import {  navigate, graphql  } from "gatsby";

const PartnershipElem = ({ img, url, desc, name }) => {
  return (
    <Zoom>
      <div className="col-md-4 col-sm-6 " onClick={() => navigate(url)}>
        <div className="our-team noMouseHover">
          <div className="pic">
            <Img className={"image img-fluid"} fluid={img} />
          </div>
          <div className="content">
            <h3 className="title">{name}</h3>
            <span className="post">{desc.split("\\n").map((v,idx)=><div key={v+idx}>{v}</div>)}</span>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

const IndexPage = ({ data }) => (
  <Layout
    title="A vida é feita de parcerias - parcerias para clientes da EMMIM®"
    description="A vida é feita de parcerias - Juntos somos mais fortes"
  >
    <Header
      smallSubtitle="Juntos somos mais fortes"
      title="A vida é feita de parcerias"
      active="parcerias"
      className="default"
    />

    <div className="content container crew-container">
      <div className="row justify-content-md-center">
        <Fade>
          <div className="col-sm-10 text-center p-5  text-primary">
            <h1>
              Trabalhamos nos bastiadores para proporcionar as melhores
              condições aos nossos especialistas
            </h1>
          </div>
          <div className="col-sm-10 text-center text-color">
            <h3>... e mais estarão para vir</h3>
          </div>
        </Fade>
      </div>
      <div className="row justify-content-md-center p-5">
        <PartnershipElem
          url="https://www.aptf.org/"
          img={data.terapeutasFala.childImageSharp.fluid}
          name={"Associação portuguesa de terapeutas da fala"}
          desc={
            "Associação portuguesa de terapeutas da fala\\nMembros da APTF tem desconto no alguer de consultórios à unidade no cowork de Terapeutas EMMIM®"
          }
        />

        <PartnershipElem
          url="https://www.chinarte.com/"
          img={data.chinarte.childImageSharp.fluid}
          name={"Chinarte"}
          desc={
            "Escola de Medicina Chinesa\\nAlunos ou Ex-alunos da Escola Chinarte tem desconto no alguer de consultórios à unidade no cowork de Terapeutas EMMIM®"
          }
        />
       <PartnershipElem
          url="http://voxmedia.pt//"
          img={data.voxmedia.childImageSharp.fluid}
          name={"Voxmedia"}
          desc={
            "Voxmedia, comunicação e Marketing, SA\\nOs terapeutas da EMMIM® terão um desconto nos serviços acordados"
          }
        />
      </div>
      <div className="row justify-content-md-center p-5">
        <PartnershipElem
          url="https://www.mygon.com/"
          img={data.mygon.childImageSharp.fluid}
          name={"Mygon"}
          desc={
            "Plataforma de reservas e promoções online\\nOs terapeutas da EMMIM® terão uma redução na taxa paga à MYGON"
          }
        />
       
        
      </div>
    </div>
    <Fade>
      <section className={`contact-section partnership`} id="contacts">
        <svg viewBox="0 0 1920 355" className="wave">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g transform="translate(0.000000, -402.000000)" fill="#fff">
              <path
                d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </svg>
        <div className="mb-0">
          <h1 className="text-center">
            Novas Parcerias
            <div>serão sempre bem vindas</div>
          </h1>

          <h4 className="text-center">Contacte-nos através do e-mail </h4>
          <h4>
            <a href="mailto:parcerias@emmim.pt" title="Email Parcerias">
              parcerias@emmim.pt
            </a>{" "}
            e entraremos em contacto o mais breve possível
          </h4>
        </div>
      </section>
    </Fade>

    <SocialLinks />
    <Footer />
  </Layout>
);
export const query = graphql`
  query PartnershipImages {
    chinarte: file(relativePath: { eq: "chinarte.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    doctorino: file(relativePath: { eq: "doctorino.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mygon: file(relativePath: { eq: "mygon.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sapo: file(relativePath: { eq: "sapoVoucher.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    voxmedia: file(relativePath: { eq: "voxmedia.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    terapeutasFala: file(relativePath: { eq: "terapeutasFala.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
